/* eslint-disable */

import domready from "@zemax/mf-js/modules/dom/ready";
import Observable from "@zemax/mf-js/modules/core/observable";

const consentUI = (options) => {
	const hashtag = options.hashtag || "#cookies-consent";
	const getText = options.getText;

	const STATUS_WAIT = "wait";
	const STATUS_TRUE = "true";
	const STATUS_FALSE = "false";

	/**************************************************
	 * MANAGER
	 **************************************************/

	let manager = false;

	const setManager = (m) => (manager = m);

	/**************************************************
	 * EVENT DISPATCHER
	 **************************************************/

	const consentUIObservable = Object.assign({}, Observable);

	/**************************************************
	 * BANNERS
	 **************************************************/

	const banner = document.createElement("div");
	const bannerFloatingMenu = document.querySelector(
		".cookies-banner__floating-menu"
	);
	const closeBanner = () => {
		if (banner.parentNode) {
			banner.parentNode.removeChild(banner);
		}
	};

	const createBanner = () => {
		banner.className = "cookies-banner cookies-banner--active";

		// Banner Wrapper

		const bannerWrapper = document.createElement("div");
		bannerWrapper.className = "wrapper";
		banner.appendChild(bannerWrapper);

		// Banner Text

		const bannerText = document.createElement("div");
		bannerText.className = "cookies-banner__text";
		bannerWrapper.appendChild(bannerText);

		bannerText.innerHTML = getText("banner");

		// Banner Actions

		const bannerActions = document.createElement("div");
		bannerActions.className = "cookies-banner__actions";
		bannerWrapper.appendChild(bannerActions);

		const acceptAllButton = document.createElement("button");
		acceptAllButton.className = "accept";
		acceptAllButton.type = "button";
		acceptAllButton.tabIndex = "0";
		acceptAllButton.innerHTML = getText("accept");

		bannerActions.appendChild(acceptAllButton);

		acceptAllButton.addEventListener("click", () => {
			manager.acceptAll();
			manager.consentUpdate();
			closeBanner();
		});

		const detailsButton = document.createElement("button");
		detailsButton.className = "details";
		detailsButton.type = "button";
		detailsButton.tabIndex = "0";
		detailsButton.innerHTML = getText("customise");
		bannerActions.appendChild(detailsButton);

		detailsButton.addEventListener("click", () => {
			createDetails();
		});

		const rejectButton = document.createElement("button");
		rejectButton.className = "reject";
		rejectButton.type = "button";
		rejectButton.tabIndex = "0";
		rejectButton.innerHTML = getText("deny");
		bannerActions.appendChild(rejectButton);

		rejectButton.addEventListener("click", () => {
			manager.denyAll();
			closeBanner();
		});

		// Init

		domready(() => {
			document.body.appendChild(banner);
			consentUIObservable.trigger({ type: "createBanner" });
		});
	};

	/**************************************************
	 * DETAILS
	 **************************************************/

	// External button to open details externally. (Ex: Cookies policiy)
	const checkDetailsTrigger = () => {
		const detailsExternalButtons = document.querySelectorAll(
			"a[href='#cookies-config']"
		);

		if (detailsExternalButtons.length > 0) {
			Array.from(detailsExternalButtons).forEach((detailsExternalButton) => {
				// Iterate over HTMLCollection https://stackoverflow.com/questions/3871547/js-iterating-over-result-of-getelementsbyclassname-using-array-foreach
				detailsExternalButton.addEventListener("click", () => {
					createDetails();
				});
			});
		}
	};
	checkDetailsTrigger();

	window.addEventListener("locationchange", () => {
		checkDetailsTrigger();
	});

	const createDetails = () => {
		const details = document.createElement("div");
		details.className = "cookies-banner__details";

		if (bannerFloatingMenu) {
			bannerFloatingMenu.classList.add("cookies-banner__floating-menu__hide");
		}

		const closeDetails = () => {
			if (
				bannerFloatingMenu &&
				bannerFloatingMenu.classList.contains(
					"cookies-banner__floating-menu__hide"
				)
			) {
				bannerFloatingMenu.classList.remove(
					"cookies-banner__floating-menu__hide"
				);
			}
			document.body.removeChild(details);
			window.removeEventListener("keydown", keyListener, false);

			if (hashtag !== "" && document.location.hash === hashtag) {
				document.location.hash = "";
			}
		};

		// Background

		const detailsBackground = document.createElement("div");
		detailsBackground.className = "cookies-banner__background";
		details.appendChild(detailsBackground);

		detailsBackground.addEventListener("click", closeDetails);

		// Modal

		const detailsModal = document.createElement("div");
		detailsModal.className = "cookies-banner__modal";
		details.appendChild(detailsModal);

		// Details Text

		const detailsText = document.createElement("div");
		detailsText.className = "modal__text";
		detailsModal.appendChild(detailsText);

		detailsText.innerHTML = getText("details");

		const createItem = (options) => {
			const { mandatory, serviceName, service, status, label, accept, deny } =
				options;

			const item = document.createElement("div");
			item.className = "cookies-banner__item";
			item.setAttribute("data-mandatory", mandatory ? "true" : "false");
			item.setAttribute("data-status", status);

			// Label

			const itemLabel = document.createElement("div");
			itemLabel.className = "cookies-banner__item__label";
			itemLabel.innerHTML = label;
			item.appendChild(itemLabel);

			if (!!options.description) {
				const itemDescription = document.createElement("p");
				itemDescription.className = "cookies-banner__item-description";
				itemDescription.innerHTML = options.description;
				itemLabel.appendChild(itemDescription);
			}

			if (!!options.uri) {
				const itemPolicyLink = document.createElement("p");
				itemPolicyLink.className = "cookies-banner__item-policy";
				itemPolicyLink.innerHTML = `<a href="${
					options.uri
				}" target="_blank">${getText("policyLinkLabel")}</a>`;
				itemLabel.appendChild(itemPolicyLink);
			}

			// Choices

			const itemChoices = document.createElement("div");
			itemChoices.className = "cookies-banner__item__choices";
			item.appendChild(itemChoices);

			const switchButton = document.createElement("label");
			switchButton.id = `${serviceName}__item__switch`;
			switchButton.className = "cookies-banner__item__switch";
			itemChoices.appendChild(switchButton);

			const switchCheckbox = document.createElement("input");
			switchCheckbox.id = `${serviceName}__item__checkbox`;
			switchCheckbox.type = "checkbox";
			switchCheckbox.checked = status === "true" ? "checked" : "";
			switchButton.appendChild(switchCheckbox);

			const switchSpan = document.createElement("span");
			switchSpan.className = "switch__slider";
			switchButton.appendChild(switchSpan);

			if (mandatory) {
				const itemMandatory = document.createElement("div");
				itemMandatory.className = "cookies-banner__item-mandatory";
				itemMandatory.innerHTML = getText("mandatory");
				itemChoices.appendChild(itemMandatory);
			} else {
				switchCheckbox.addEventListener("click", (e) => {
					const currentStatus = item.getAttribute("data-status");
					const switchCheckbox = document.getElementById(e.target.id);

					if (currentStatus === STATUS_TRUE) {
						item.setAttribute("data-status", STATUS_FALSE);
						switchCheckbox.checked = false;
						service.status = STATUS_FALSE;
					} else {
						service.status = STATUS_TRUE;
					}
				});
			}

			return item;
		};

		const services = manager.services();

		// Items All

		const detailsAll = document.createElement("div");
		detailsAll.className = "cookies-banner__details--all";
		detailsModal.appendChild(detailsAll);

		// Items

		const detailsItems = document.createElement("div");
		detailsItems.className = "cookies-banner__details--items";
		detailsModal.appendChild(detailsItems);

		// Append necessary cookies to details items
		const necessary = document.createElement("div");
		necessary.innerHTML = `<div class="cookies-banner__item" data-mandatory="false" data-status="true"><div class="cookies-banner__item__label">${necessaryTitle}</div><div class="cookies-banner__item__choices"><label id="necessary__item__switch" class="cookies-banner__item__switch"><span class="cookies-banner__neccesary__icon__container"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21" fill="black"><path d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM5 5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5V5ZM14 19H2V9H14V19ZM8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16Z"></path></svg></span><input id="necessary__item__checkbox" type="checkbox" checked disabled><span class="switch__slider switch__slider--necessary"></span></label></div></div>`;
		("");
		detailsAll.appendChild(necessary);

		// Append optional title
		const optional = document.createElement("div");
		optional.innerHTML = getText("optional");
		optional.className = "cookies-banner__optional-title";
		detailsAll.appendChild(optional);

		// Append all services to details items
		services.forEach((item) =>
			detailsItems.appendChild(
				createItem({
					mandatory: !!item.service[0].mandatory,
					status: item.status,
					serviceName: item.key,
					service: item,
					label: item.service[0].name,
					description: !!item.service[0].description
						? item.service[0].description
						: false,
					uri: !!item.service[0].uri ? item.service[0].uri : false,
					accept: () => manager.accept(item.key),
					deny: () => manager.deny(item.key),
				})
			)
		);

		// Details close

		const closeButton = document.createElement("div");
		closeButton.className = "cookies-banner__close cookies-banner__close--dark";
		closeButton.tabIndex = "0";
		closeButton.role = "button";
		closeButton.ariaPressed = "false";
		closeButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" role="img" aria-labelledby="closeCookiesButtonTitle closeCookiesButtonDesc"><title>
			${cookiesCloseButtonText}
			</title><desc id="closeCookiesButtonDesc">Pulsa este botón para cerrar el banner de cookies</desc><path fill="#FFF" d="M28.941 31.786L.613 60.114a2.014 2.014 0 1 0 2.848 2.849l28.541-28.541 28.541 28.541c.394.394.909.59 1.424.59a2.014 2.014 0 0 0 1.424-3.439L35.064 31.786 63.41 3.438A2.014 2.014 0 1 0 60.562.589L32.003 29.15 3.441.59A2.015 2.015 0 0 0 .593 3.439l28.348 28.347z"></path></svg>`;
		detailsModal.appendChild(closeButton);

		closeButton.addEventListener("click", closeDetails);

		const keyListener = (evt) => {
			if (evt.keyCode === 27) {
				closeDetails();
			}
		};

		window.addEventListener("keydown", keyListener, false);

		// Save

		const itemSave = document.createElement("div");
		itemSave.className = "cookies-banner__save";
		detailsModal.appendChild(itemSave);

		// Allow all cookies

		const saveAllButton = document.createElement("button");
		saveAllButton.className = "save";
		saveAllButton.type = "button";
		saveAllButton.innerHTML = getText("allServices");
		itemSave.appendChild(saveAllButton);

		saveAllButton.addEventListener("click", () => {
			manager.acceptAll();
			manager.consentUpdate();
			closeDetails();
			closeBanner();
		});

		// Allow selected cookies

		const saveButton = document.createElement("button");
		saveButton.className = "save";
		saveButton.type = "button";
		saveButton.innerHTML = getText("save");
		itemSave.appendChild(saveButton);

		saveButton.addEventListener("click", () => {
			services.forEach((item) => {
				manager.save(item);
			});
			manager.consentUpdate();
			closeDetails();
			closeBanner();
		});

		// Init

		domready(() => {
			document.body.appendChild(details);
			consentUIObservable.trigger({ type: "createDetails" });
		});
	};

	/**************************************************
	 * EVENTS
	 **************************************************/

	const requireConsent = () => createBanner(manager);

	if (hashtag !== "") {
		window.addEventListener(
			"hashchange",
			() => {
				if (document.location.hash === hashtag) {
					createDetails();
				}
			},
			false
		);

		if (document.location.hash === hashtag) {
			domready(() => createDetails());
		}
	}

	const o = {
		setManager,
		requireConsent,
		consentUIObservable: consentUIObservable,
	};

	return o;
};

export default consentUI;
