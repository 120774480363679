import Swiper from "swiper/bundle"; // eslint-disable-line

class Timeline {
	constructor() {
		Array.from(document.querySelectorAll(".js-timeline")).forEach((el) =>
			this.init(el)
		);
	}

	init(el) {
		let width = window.innerWidth;
		const swiper = document.querySelector(".swiper-container");

		this.timeline = new Swiper(el.querySelector(".swiper-container"), {
			freeMode: true,
			grabCursor: true,
			spaceBetween: 24,

			// Navigation arrows
			navigation: {
				nextEl: el.querySelector(".js-timeline-button-next"),
				prevEl: el.querySelector(".js-timeline-button-prev"),
			},

			// Breakpoints
			breakpoints: {
				0: {
					slidesPerView: "auto",
					slidesPerGroup: 1,
					centeredSlides: false,
				},
			},
		});

		function swiperPadding(w) {
			let pos = 24;

			if (w >= 1156) {
				pos = (w - 1156) / 2 + 48;
			} else if (w >= 992) {
				pos = 32 + 56;
			}

			swiper.style = "cursor: grab; padding-left: " + pos + "px !important;"; // eslint-disable-line
		}

		swiperPadding(width);

		window.addEventListener("resize", () => {
			width = window.innerWidth;

			swiperPadding(width);
		});
	}
	/* eslint-enable */
}

export default Timeline;
