/* eslint-disable class-methods-use-this, prefer-destructuring, no-use-before-define, no-shadow */
class Header {
	constructor() {
		this.initHeader();
	}

	initHeader() {
		const header = document.getElementById("header");
		const headerHeight = header.offsetHeight + 60;
		let distanceToTop = document.documentElement.scrollTop;

		// Menu button event listener
		const mobileMenuToogler = document.getElementById("main-menu-toggler");
		if (mobileMenuToogler) {
			mobileMenuToogler.addEventListener("click", blockScroll);
		}

		// Scroll event listener
		window.addEventListener("scroll", throttle(callback, 200));

		if (distanceToTop > 0) {
			header.classList.add("scrolled");
		}

		function blockScroll() {
			const body = document.body;
			if (!this.classList.contains("menu-item-has-children")) {
				if (body.classList.contains("u-mobile-block-scroll")) {
					document.body.classList.remove("u-mobile-block-scroll");
				} else {
					document.body.classList.add("u-mobile-block-scroll");
				}
			}
		}

		function throttle(callback, wait = 100) {
			let timer = null;

			return (args) => {
				if (timer === null) {
					timer = setTimeout(() => {
						callback.apply(this, args);
						timer = null;
					}, wait);
				}
			};
		}

		function callback() {
			const newDistanceToTop = document.documentElement.scrollTop;

			if (newDistanceToTop > headerHeight) {
				header.classList.add("scrolled");
			}

			if (newDistanceToTop < distanceToTop) {
				header.classList.add("scrolled--show");
			} else {
				header.classList.remove("scrolled--show");
			}

			if (newDistanceToTop === 0) {
				setTimeout(() => {
					header.classList.remove("scrolled", "scrolled--show");
				}, 1000);
			}

			distanceToTop = newDistanceToTop;
		}

		// Set a CSS variable to fix Webkit-based mobile phones handling of vh units.
		// You can use it with the vh() SASS function.
		function setCSSvh() {
			document.documentElement.style.setProperty(
				"--vh",
				`${window.innerHeight}px`
			);
		}

		window.addEventListener("resize", setCSSvh);
		window.addEventListener("orientationchange", setCSSvh);
		setCSSvh();
	}
}

export default Header;
