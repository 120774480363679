/* eslint-disable class-methods-use-this, prefer-destructuring, no-undef, no-shadow, no-unused-vars */
import FsLightbox from "fslightbox"; // eslint-disable-line

class Cta {
	constructor() {
		this.videoPlayButtons = document.getElementsByClassName("js-video-play");
		if (this.videoPlayButtons[0]) {
			this.init();
		}
	}

	init() {
		const videoPlayButtons = this.videoPlayButtons;

		refreshFsLightbox(); // eslint-disable-line

		Array.prototype.forEach.call(videoPlayButtons, (videoPlayButton) => {
			const lightboxDataAttribute = videoPlayButton.dataset.fslightbox;
			// Autoplay popup video
			fsLightboxInstances[lightboxDataAttribute].props.onOpen = (
				FsLightbox
			) => {
				const video = document.querySelectorAll(
					".fslightbox-container video"
				)[0];

				if (video) {
					video.load();
					video.addEventListener("canplaythrough", () => {
						video.play();
					});
				}
			};
		});
	}
}

export default Cta;
