/* eslint-disable */

export const gtmService = (name, more = () => true) => ({
	key: "marketing",
	type: "api",
	name: name, // eslint-disable-line
	uri: "",
	cookies: [],
	init: () => {},
	accept: () => {
		window.cookiesConsent.ad_storage = "granted";
		window.cookiesConsent.ad_personalization = "granted";
		window.cookiesConsent.ad_user_data = "granted";
		more();
	},
	deny: () => {
		window.cookiesConsent.ad_storage = "denied";
		window.cookiesConsent.ad_personalization = "denied";
		window.cookiesConsent.ad_user_data = "denied";
		more();
	},
	refuse: () => true,
});

export default gtmService;
