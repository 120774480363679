class LottieLoader {
	constructor() {
		this.animatedElementsContainers =
			document.getElementsByClassName("js-lottie");
		this.lottieInstance = window.lottie;
		if (this.animatedElementsContainers) {
			this.init();
		}
	}

	loadAnimation(animatedElementContainer, animationPath) {
		if (this.lottieInstance) {
			this.lottieInstance.loadAnimation({
				container: animatedElementContainer, // the dom element that will contain the animation
				renderer: "svg",
				loop: true,
				autoplay: true,
				path: animationPath, // the path to the animation json
				rendererSettings: {
					preserveAspectRatio: "xMidYMax slice",
				},
			});
		}
	}

	init() {
		Array.prototype.forEach.call(
			this.animatedElementsContainers,
			(animatedElementContainer) => {
				const animationPath = animatedElementContainer.getAttribute(
					"data-animation-path"
				);
				if (animationPath !== undefined && animationPath !== null) {
					if (this.lottieInstance === undefined) {
						window.loadJS(
							"/wp-content/themes/incus-capital/js/vendor/lottie.js",
							() => {
								this.lottieInstance = window.lottie;
								this.loadAnimation(animatedElementContainer, animationPath);
							}
						);
					} else {
						this.loadAnimation(animatedElementContainer, animationPath);
					}
				}
			}
		);
	}
}

export default LottieLoader;
