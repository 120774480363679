/*
Services. Check https://github.com/AmauriC/tarteaucitron.js/blob/master/tarteaucitron.services.js if you need to add more services.
Then adapt the service taking https://github.com/zemax/tartemeringuee/tree/master/services as an example.
*/

import addScript from "tartemeringuee/utils/addscript";
import analytics from "../../lib/cookies/analytics"; // eslint-disable-line
import marketing from "../../lib/cookies/marketing"; // eslint-disable-line
import preferences from "../../lib/cookies/preferences"; // eslint-disable-line
import consentUI from "../../lib/cookies/consent-ui"; // eslint-disable-line
import consentManager from "../../lib/cookies/consent-manager"; // eslint-disable-line

class Cookies {
	constructor() {
		if (
			typeof cookiesPolicyLink !== "undefined" &&
			typeof googleTagManagerId !== "undefined" &&
			googleTagManagerId !== "" // eslint-disable-line
		) {
			// Define Google Tag Manager dataLayer and the gtag function.

			/* eslint-disable */
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			/* eslint-enable */

			// Set default Google Tag Manager consent to 'denied' as a placeholder
			window.cookiesConsent = {
				ad_storage: "denied",
				analytics_storage: "denied",
				ad_user_data: "denied", // Sets consent for sending user data related to advertising to Google. Controls whether user data can be sent to Google for advertising purposes.
				ad_personalization: "denied", // Sets consent for personalized advertising. Controls whether personalised advertising (remarketing) can be enabled.
				functionality_storage: "denied",
				personalization_storage: "denied",
				security_storage: "granted",
				wait_for_update: 1000, // If your banner loads asynchronously, it might not always run before your Google tags. To handle such situations, specify wait_for_update along with a millisecond value to control how long to wait before data is sent.
			};

			// Manage cookies consent.
			/* eslint-disable */
			const consent = consentManager({
				cookieName: "cookies_consent",
				showMandatoryWaiting: true,
			});
			consent
				.register(preferences(cookiesPreferencesText))
				.register(marketing(cookiesMarketingText))
				.register(analytics(cookiesAnalyticsText))
				.setUI(
					consentUI({
						getText: getText({ privacyURL: cookiesPolicyLink }),
					})
				)
				.launch();

			// Send Google Tag Manager default consent.
			gtag("consent", "default", window.cookiesConsent);

			// Remove wai_for_update from the Google Tag Manager consent array.
			delete window.cookiesConsent.wait_for_update;

			// Load Google Tag Manager.
			addScript(`//www.googletagmanager.com/gtm.js?id=${googleTagManagerId}`); // eslint-disable-line

			gtag("js", new Date());
			gtag("config", googleTagManagerId); // eslint-disable-line
		}
	}
}

export default Cookies;
